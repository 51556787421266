const messages = {
  cn: '中',
  en: 'EN',
  logout: '退出',
  back: '返回',
  task: '任务管理',
  theme: {
    light: '浅色',
    dark: '暗色',
    auto: '自动'
  },
  export: '导出',
  selectExportProps: '选择要导出的属性',
  upload: {
    uploadBtnText: '文件上传',
    uploadTip: '{formats} 格式的文件',
    fileSizeExceed: '文件大小超过限制',
    single: '单个文件',
    multiple: '多个文件',
    example: '示例文件'
  },
  taskTip: '创建时间 {createTime}',
  logoText: 'iTSCP',
  confirm: '确定',
  cancel: '取消',
  reset: '重置',
  processData: '数据处理',
  processComplete: '数据处理完成',
  plotFigures: '图像生成',
  showResult: '结果展示',
  downloadResult: '结果下载',
  downloadParams: '参数下载',
  downloadPictures: '全部图片下载',
  nextStep: '用此数据进行下一步操作',
  placeText: '请选择',
  plotText: '画图参数输入',
  qcResults: '质控结果'
}

export default messages
