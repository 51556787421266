const messages = {
  choose: '请选择数据',
  upload: '请上传文件',
  method: '请选择方法',
  config: '请选择配置文件',
  dataInput: '数据输入',
  dataChoose: '现有数据选择',
  localUpload: '本地上传',
  configChoose: '现有配置选择',
  configUpload: '本地配置上传',
  dataFilter: '数据筛选',
  paramsInput: '参数输入',
  methodChoose: '方法选择',
  processData: '数据处理',
  params: {
    checkParams: '请输入参数',
    selectParams: '请选择参数',
    checkPattern: '请输入字母/数字/下划线',
    checkListPattern: '请输入字母/数字/下划线,以英文逗号或空格分隔',
    checkNumberPattern: '请输入数字,以英文逗号或空格分隔',
    checkRange: '请输入{min}-{max}范围内的数',
    preProcessing: {
      checkMinGenes: '只可输入0-30000范围内的数',
      checkMaxGenes: '只可输入0-90000范围内的数',
      checkNormCount: '只可输入100-100000范围内的数',
      checkNTop: '只可输入100-20000范围内的数',
      checkMinMean: '只可输入0.0001-1范围内的数',
      checkMaxMean: '只可输入1-100范围内的数',
      checkNumberOfNeighbors: '只可输入1-10000范围内的数',
      checkNComponents: '只可输入1-1000范围内的数',
      checkMaxValue: '只可输入1-1000范围内的数'
    },
    placeholder: {
      clusterName: '请在上述 “obs” 属性中选择一个聚类结果的名称'
    }
  }
}

export default messages
