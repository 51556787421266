const messages = {
  // name/intro: 首页标题描述, title/desc: 功能页标题描述
  preProcessing: {
    title: '数据预处理',
    desc: '对数据进行质控, 特征筛选和降维'
  },
  dataIntegration: {
    title: '数据合并',
    desc: '这是数据合并模块的描述'
  },
  clustering: {
    title: '聚类',
    desc: '分析具有相似表达谱的细胞簇'
  },
  differentialExpression: {
    title: '差异表达',
    desc: '对基因表达频率的差异进行统计分析'
  },
  cellTypeAnnotation: {
    title: '细胞标注',
    desc: '将生物标签注释给待确认的细胞'
  },
  geneSetEnrichment: {
    title: '富集分析',
    desc: '将基因按照先验知识，也就是基因组注释信息，对基因进行分类的过程'
  },
  trajectoryAnalysis: {
    title: '轨迹分析',
    desc: '使用系统发育方法来沿着时间连续性的轨迹对细胞进行排序来识别细胞状态之间的过渡和分化过程'
  },
  cellCommunication: {
    title: '细胞间通讯',
    desc: '分析细胞类型之间的相互作用'
  },
  inferCNV: {
    title: '拷贝数变异分析',
    desc: '探索肿瘤单细胞RNA-seq数据，分析其中的体细胞大规模染色体拷贝数变化'
  },
  downstreamAnalysis: {
    name: '单细胞数据分析',
    title: '数据分析',
    intro: '对单细胞表达矩阵数据进行细胞聚类, 来探索作为不同聚类标记的基因，帮助识别每个聚类的细胞类型。同时, 对细胞类型和细胞轨迹进行分析, 进一步了解细胞。'
  },
  advancedDownstreamAnalysis: {
    title: '进阶数据分析',
    desc: '端到端的数据分析流程, 输入配置文件即可获得全部结果'
  },
  convertFastq: {
    name: '单细胞表达矩阵计算',
    title: 'FastQ转换表达矩阵',
    desc: '将测序reads与参考转录组进行比对获得细胞和基因的表达值计数矩阵',
    intro: '单细胞原始测序数据生成表达矩阵的过程步骤包括：格式化reads并过滤嘈杂的细胞条形码, 分离样本, Mapping/pseudo-mapping到转录组, 去重UMIs并量化reads等。'
  },
  molecularSubtyping: {
    name: '分子分型',
    title: '分子分型',
    desc: '对结直肠癌患者和乳腺癌患者组织转录组数据进行分析，可推断其属于的结直肠CMS分子分型和乳腺PAM50分子分型，用于精准医疗',
    intro: '对结直肠癌患者和乳腺癌患者组织转录组数据进行分析，可推断其属于的结直肠CMS分子分型和乳腺PAM50分子分型，用于精准医疗。'
  },
  VDJMiner: {
    name: '免疫组库分析',
    title: '免疫组库分析',
    desc: '使用可解释人工智能模型和TCR免疫组库数据对疾病状态，预后和药物治疗效果进行预测。基于免疫组数据设计，但是支持所有表格数据，比如，病人的临床信息和分子标记物等等',
    intro: '使用可解释人工智能模型和TCR免疫组库数据对疾病状态，预后和药物治疗效果进行预测。基于免疫组数据设计，但是支持所有表格数据，比如，病人的临床信息和分子标记物等等。'
  },
  knowledgeGraph: {
    name: '单细胞知识图谱',
    title: '知识图谱',
    desc: '基于海量数据和AI技术实现知识提取,表征学习和知识图谱构建',
    intro: '融合多种AI技术的基因-细胞知识图谱。基于海量数据和前沿AI技术，实现知识提取，知识表征学习，知识图谱构建，提供基因-细胞层面的智能搜索，智能问答和智能推荐，致力于推动单细胞基因测序技术对科研和医疗的指导。'
  },
  spatialTranscriptomics: {
    name: '空间组学数据库SODB',
    title: '空间组学数据库SODB',
    desc: '融合细胞空间排列和细胞测序两种模态的数据获得完整空间基因表达图谱',
    intro: 'SODB是一个大规模空间组学数据库，包括空间转录组、蛋白组、代谢组、基因组和多组学技术，提供了交互式用户界面和高效的数据管理方案、统一的数据格式以方便与主流分析工具无缝衔接。我们还提供一个新颖的数据可视化方式，可以快速地观察每一个样本整体的分子和空间特征。'
  }
}

export default messages
