const messages = {
  loginOptionSelect: '选择登录方式',
  qqLogin: 'QQ 登录',
  wechatLogin: '微信登录',
  mailLogin: '邮箱登录',
  noAccount: '没有账号？',
  registerNow: '立即注册',
  mailInputTip: '请输入邮箱地址',
  passwordInputTip: '请输入密码',
  captchaInputTip: '请输入验证码',
  mailEmptyError: '邮箱不能为空',
  mailNotCorrect: '邮箱格式不正确',
  usernameEmptyError: '用户名不能为空',
  passwordEmptyError: '密码不能为空',
  passwordNotMatch: '输入的密码不匹配',
  captchaEmptyError: '验证码不能为空',
  captchaNotCorrect: '验证码不正确',
  captchaUpdateTip: '点击切换验证码',
  loginBtnText: '登录',
  otherLoginOption: '切换其他登录方式',
  forgetPassword: '忘记密码',
  loginConfirm: '登录确认',
  loginConfirmMsg: '没有登录或者登录已过期, 是否跳转到登录页面',
  mailRegister: '邮箱注册',
  confirmPassword: '输入确认密码',
  registerBtnText: '同意并提交',
  usernameInputTip: '请输入用户名',
  term: '我已经阅读并同意{tos}',
  tos: '免责声明',
  recoverPassword: '找回密码',
  confirm: '确认',
  backToHome: '返回首页',
  resetLinkSendSuccessTips: '密码重置链接已经发送到您的邮箱, 请按照邮件指示重置密码。',
  resetPassword: '重置您的密码',
  resetSuccess: '重置成功',
  resetSuccessTips: '您已成功完成重置密码, 请前往登录账号',
  register: {
    successTitle: '注册成功',
    activateTips: '系统已经给您发了一封电子邮件, 请按照指示激活您的账号。',
    verifyNow: '立即验证',
    specify: '请指定',
    industryEmptyTips: '请指定行业信息',
    organizationInputTips: '机构/公司/学校',
    careerInputTips: '请输入职业信息',
    industryEmptyError: '行业信息不能为空',
    organizationEmptyError: '机构/公司/学校不能为空',
    careerEmptyError: '职业不能为空',
    acceptEmailTips: '接收有关调查问卷、算法更新或者新闻相关的电子邮件通知',
    acceptEmailPrompt: '您是否愿意接收有关调查问卷、算法更新或者新闻相关的电子邮件通知？',
    mailSelectWarning: '为了保证您能顺利完成注册, 建议使用公司或者学校邮箱注册, 不要使用个人邮箱'
  }
}

export default messages
