const messages = {
  home: '首页',
  showAll: '显示所有',
  ConvertFastq: 'FastQ转换表达矩阵',
  dataProcessing: '数据处理',
  DataIntegration: '数据合并',
  PreProcessing: '数据预处理',
  DownstreamAnalysis: '数据分析',
  Clustering: '聚类',
  DifferentialExpression: '差异表达',
  CellTypeAnnotation: '细胞标注',
  GeneSetEnrichment: '富集分析',
  CellCommunication: '细胞间通讯',
  InferCNV: '拷贝数变异分析',
  TrajectoryAnalysis: '轨迹分析',
  AdvancedDownstreamAnalysis: '进阶数据分析',
  MolecularSubtyping: '分子分型',
  KnowledgeGraph: '知识图谱',
  SpatialTranscriptomics: '空间基因组学'
}

export default messages
