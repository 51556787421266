const messages = {
  taskTitle: '任务管理中心',
  recentTasks: '最近任务',
  displayTip: '仅显示最近10条任务: ',
  deleteConfirmTitle: '任务删除确认',
  deleteConfirmContent: '确定要删除任务吗？删除之后不能撤销',
  runTime: '处理时间',
  id: '任务 ID',
  name: '项目名称',
  cancel: '取消任务',
  status: '运行进度',
  action: '数据处理结果',
  check: '查看',
  delete: '删除',
  download: '下载',
  complete: '任务完成',
  running: '数据处理中',
  error: '任务处理失败',
  createTime: '创建时间',
  module: '功能模块',
  taskStatus: '任务状态',
  result: '筛选结果',
  showAll: '显示所有',
  twentyFourHours: '仅显示24小时内',
  fiveDays: '仅显示5天内',
  taskSuccess: '任务完成',
  taskFailed: '任务失败'
}

export default messages
