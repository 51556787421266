import RequestMessage from './request'
import NavMessage from './nav'
import TaskMessage from './task'
import FormMessage from './form'
import BrowserMessage from '../../common/cn/browser'
import CommonMessage from '../../common/cn/common'
import ModulesMessage from '../../common/cn/modules'
import LoginRegisterMessage from '../../common/cn/login_register'
// @ts-ignore
import elementCnLocale from 'element-ui/lib/locale/lang/zh-CN'

const messages = {
  common: CommonMessage,
  request: RequestMessage,
  nav: NavMessage,
  browser: BrowserMessage,
  modules: ModulesMessage,
  task: TaskMessage,
  form: FormMessage,
  loginRegister: LoginRegisterMessage,
  ...elementCnLocale
}

export default messages
